import {
  DatabaseReference,
  child,
  get,
  limitToLast,
  orderByChild,
  query,
  update,
} from 'firebase/database'
import { useEffect, useState } from 'react'
import { serverTimestamp } from '../firebase/serverValue'
import { dataState, loadingState } from '../types/asyncState'
import { FirebaseKey, Flag, REPORT_KEY_FLAG, UserId } from '../types/utils'
import { queueSortIndex } from '../utils/queueSortIndex'

export interface QueueItem {
  labelerUids?: string[]
  priority: number
  sortIndex: number
  labelersTarget: number
  flag?: Flag
}

export type QueueEntry<T extends QueueItem> = {
  key: FirebaseKey
  value: T
}

type Items<T> = Record<FirebaseKey, T>

function randomlyPickFromItems<T extends QueueItem>(items: Items<T>) {
  const itemKeys = Object.keys(items)
  const nbKeys = itemKeys.length
  if (nbKeys === 0) return null

  const randomIndex = Math.floor(Math.random() * nbKeys)
  const key = itemKeys[randomIndex]
  return { key, value: items[key] }
}

export function useQueue<T extends QueueItem>(
  userId: UserId,
  queueReference: DatabaseReference,
) {
  const BATCH_SIZE = 10
  const [fetchSize, setFetchSize] = useState<number>(BATCH_SIZE)

  // Current item being labeled
  // undefined = not set / loading state
  // null = no QueueEntry available
  const [head, setHead] = useState<QueueEntry<T> | null | undefined>()

  // Check if there is an other item available. At start and possibly after
  useEffect(() => {
    async function runEffect() {
      if (head) return // if head is available, nothing to do

      const snapshot = await get(
        query(
          queueReference,
          orderByChild('sortIndex'),
          limitToLast(fetchSize),
        ),
      )

      const items = (snapshot.val() ?? {}) as Items<T>

      // Remove items that:
      // 1. Have already been labeled by this user
      // 2. Have a flag
      Object.entries<T>(items).forEach(([key, item]) => {
        if (
          (item.labelerUids !== undefined &&
            item.labelerUids.includes(userId)) ||
          (item.flag && item.flag.type === REPORT_KEY_FLAG)
        ) {
          delete items[key]
        }
      })

      if (
        Object.keys(items).length >= BATCH_SIZE ||
        snapshot.size < fetchSize
      ) {
        setHead(randomlyPickFromItems(items))
      } else {
        setFetchSize(fetchSize + BATCH_SIZE)
      }
    }

    runEffect()
  }, [queueReference, userId, head, fetchSize])

  type UseQueue<T extends QueueItem> = {
    head: QueueEntry<T>
    pop: () => void
  } | null

  if (head === undefined) return loadingState()

  if (head === null) return dataState<UseQueue<T>>(null)

  return dataState<UseQueue<T>>({
    head,
    pop: () => setHead(undefined),
  })
}

export async function markEntryAsLabeled(
  queueReference: DatabaseReference,
  key: FirebaseKey,
  userId: UserId,
  removeFromQueueAndTag: () => Promise<unknown>,
  isSure: boolean = false,
) {
  // Check if entry is still present in queue (could have been removed by an other labeler in the meantime)
  // if it does not exists, do nothing
  const queueRef = child(queueReference, key)
  const queueItem = await get(queueRef)
  if (!queueItem.exists()) return

  // Retrieve updated queueEntry from database (labelerUids could have been updated by an other labeler in the meantime)
  const { labelerUids, priority, labelersTarget } = queueItem.val() as QueueItem

  const updatedLabelerUids = [...new Set([...(labelerUids ?? []), userId])]
  const labelersCount = updatedLabelerUids.length

  if (labelersCount >= labelersTarget || isSure) {
    // remove item when it has reached target
    await removeFromQueueAndTag()
  } else {
    // update labelers count and uids
    const sortIndex = queueSortIndex(priority, labelersCount)
    const queueUpdate = { labelerUids: updatedLabelerUids, sortIndex }
    await update(queueRef, queueUpdate)
  }
}

export async function flagKey(
  queueReference: DatabaseReference,
  key: FirebaseKey,
  userId: UserId,
) {
  const reportFlag: Flag = {
    type: REPORT_KEY_FLAG,
    createdAt: serverTimestamp(),
    userId,
  }

  await update(child(queueReference, key), { flag: reportFlag })
}
